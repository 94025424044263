<template>
    <div class="manager-index index-page" :class="$route.params.disease">
        <router-view />
        <NavigationBar />
        <transition name="fade" ease="in-out">
            <GenerateLinkAndPassword v-if="generateLinkAndPasswordPopUp" />
        </transition>
        <transition name="fade" ease="in-out">
            <AEDisclaimer v-if="aeDisclaimerPopUp" />
        </transition>
        <transition name="fade" ease="in-out">
            <ShareDisclaimer v-if="shareDisclaimerPop" />
        </transition>
        <transition name="fade" ease="in-out">
            <RevealInformation v-if="revealInformationPopup" />
        </transition>
    </div>
</template>

<script>
import NavigationBar from '@/components/Navigation/NavigationBar.vue';
import GenerateLinkAndPassword from '@/components/Popup/GenerateLinkAndPassword.vue';
import AEDisclaimer from '@/components/Popup/AEDisclaimer.vue';
import ShareDisclaimer from '@/components/Popup/ShareDisclaimer.vue';
import RevealInformation from '@/components/Popup/RevealInformation.vue';
import Login from '@/mixins/Login';
import {mapState } from 'vuex';

export default {
    components: {
        NavigationBar,
        GenerateLinkAndPassword,
        AEDisclaimer,
        RevealInformation,
        ShareDisclaimer
    },
    mixins: [Login],
    created() {
        if (this.$route.name === 'Sales_Index') this.$router.push({ name: this.defaultPage });
    },
    computed: {
        ...mapState({
            generateLinkAndPasswordPopUp: (state) => state.popup.generateLinkAndPasswordPopUp,
            aeDisclaimerPopUp: (state) => state.popup.aeDisclaimerPopUp,
            shareDisclaimerPop: (state) => state.popup.shareDisclaimerPop,
            revealInformationPopup: (state) => state.popup.revealInformationPopup,
            defaultPage: (state) => state.config.defaultPage?.sales,
        }),
    },
};
</script>
