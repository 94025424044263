<template>
    <div class="search-select">
        <el-input
            v-model="inputText"
            :placeholder="selected || '請輸入姓名'"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
        ></el-input>
        <div v-if="showSelections" class="list">
            <div v-if="loading" class="item center">查詢中</div>
            <div v-if="!loading && hcpList.length === 0" class="item center">
                查無資料
            </div>
            <div
                v-for="item in hcpList"
                :key="item.id"
                class="item"
                @click="onSelect(item)"
            >
                {{ item.label }}
            </div>
        </div>
    </div>
</template>

<script>
import API from '@/models/api';

let typeInterval;

export default {
    data() {
        return {
            selected: undefined,
            inputText: '',
            loading: false,
            isInputFocus: false,
            isSelected: false,
            hcpList: [],
        };
    },
    computed: {
        showSelections() {
            return this.inputText && (!this.isSelected || this.isInputFocus);
        },
    },
    methods: {
        onInput(val) {
            this.hcpList = [];
            this.loading = true;
            clearInterval(typeInterval);
            typeInterval = setInterval(() => {
                this.isSelected = false;
                this.remoteMethod(val);
                clearInterval(typeInterval);
            }, 1000);
        },
        onSelect(val) {
            if (this.loading) return;
            this.selected = val.label;
            this.inputText = val.label;
            this.isSelected = true;
            this.$emit('select', val);
        },
        onFocus() {
            this.isInputFocus = true;
            if (this.selected) this.inputText = '';
        },
        onBlur() {
            setTimeout(() => {
                this.isInputFocus = false;
                if (this.selected && this.inputText !== this.selected) {
                    this.isSelected = true;
                }
                if (this.selected) this.inputText = this.selected;
                if (!this.selected && this.inputText) {
                    this.isSelected = true;
                    this.inputText = '';
                }
            }, 300);
        },
        async remoteMethod(query) {
            if (query !== '') {
                try {
                    const RES = await API.CMS.search(query);
                    const DATA = [];
                    RES.data.forEach((item) => {
                        DATA.push({
                            label: item.name,
                            id: item.id,
                            value: item,
                        });
                    });
                    this.hcpList = [...DATA];
                } catch (e) {
                    this.$message({
                        message: e.response.data.msg,
                        type: 'error',
                    });
                } finally {
                    this.loading = false;
                }
            } else this.hcpList = [];
        },
    },
};
</script>

<style lang="scss" scoped>
.search-select {
    position: relative;
    width: 90%;
}
.list {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 105%);
    z-index: 1;
    box-sizing: border-box;
    max-height: 200px;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px 1px 1px 1px #00000033;
    border-radius: 8px;
    overflow: auto;
    .item {
        cursor: pointer;
        padding: 10px 20px;
        &:hover {
            background-color: #eee;
        }
        &.center {
            text-align: center;
            &:hover {
                background-color: #fff;
            }
        }
    }
}
</style>
