export default {
    methods: {
        clipboardSuccessHandler() {
            this.$message({
                message: '複製成功',
                type: 'success',
            });
        },
        clipboardErrorHandler() {
            this.$message({
                message: '複製失敗',
                type: 'error',
            });
        },
    },
};
