<template>
    <pop-up background-color="rgba(0, 0, 0, 0.7)" :on-close="onCancel">
        <div class="text">
            <div class="title">案例連結與密碼</div>
        </div>
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="form"
            class="form"
            inline
            @submit.prevent.native
            @keypress.enter.native.prevent
        >
            <div class="input-block">
                <div class="label">醫師姓名</div>
                <div v-if="!target" fluid class="input" prop="doctorInfo">
                    <SearchSelect @select="onSelect" />
                </div>
                <el-input
                    v-else
                    style="width: 90%"
                    :value="target.doctorName"
                    readonly
                >
                </el-input>
            </div>
            <div class="input-block">
                <div class="label">CT Number</div>
                <div v-if="!target" fluid class="input">
                    <el-input
                        style="width: 90%"
                        v-model="ruleForm.CTNumber"
                        placeholder="請輸入CT Number"
                    />
                </div>
                <el-input
                    v-else
                    style="width: 90%"
                    :value="target.CTNumber"
                    readonly
                >
                </el-input>
            </div>
            <div class="button">
                <button-wrapper
                    v-if="!target"
                    type="modal"
                    color="primary"
                    :disabled="
                        ruleForm.doctorInfo.value && ruleForm.CTNumber
                            ? false
                            : true
                    "
                    @click="onCreate"
                >
                    產生連結
                </button-wrapper>
            </div>
            <div v-if="target" class="divider"></div>
            <div v-if="target" class="information-block">
                <div class="input-block">
                    <div class="label">連結</div>
                    <el-input style="width: 90%" :value="link" readonly>
                    </el-input>
                    <button-wrapper
                        type="modal"
                        color="normal"
                        v-clipboard="() => link"
                        v-clipboard:success="clipboardSuccessHandler"
                        v-clipboard:error="clipboardErrorHandler"
                    >
                        複製
                    </button-wrapper>
                </div>
                <div class="input-block">
                    <div class="label">密碼</div>
                    <el-input
                        style="width: 90%"
                        :value="target.verifyCode"
                        readonly
                    >
                    </el-input>
                    <button-wrapper
                        type="modal"
                        color="normal"
                        v-clipboard="() => target.verifyCode"
                        v-clipboard:success="clipboardSuccessHandler"
                        v-clipboard:error="clipboardErrorHandler"
                    >
                        複製
                    </button-wrapper>
                </div>
            </div>
            <div v-if="target" class="button-group">
                <button-wrapper
                    :width="150"
                    type="modal"
                    color="cancel"
                    @click="onCancel"
                >
                    返回我的目錄
                </button-wrapper>
                <button-wrapper
                    type="modal"
                    color="primary"
                    @click="onNextStep"
                >
                    下一步
                </button-wrapper>
            </div>
        </el-form>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import SearchSelect from '@/components/UserInterface/SearchSelect.vue';
import CopyHandler from '@/mixins/CopyHandler';
import API from '@/models/api';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    components: { PopUp, SearchSelect },
    mixins: [CopyHandler],
    data() {
        return {
            loading: false,
            hcpList: [],
            ruleForm: {
                doctorInfo: {},
                CTNumber: undefined,
            },
            rules: {
                doctorInfo: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
                CTNumber: {
                    required: true,
                },
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            target: (state) => state.sales.target,
        }),
        link() {
            return `${process.env.VUE_APP_BASE_URL}${this.$route.params.disease}/doctor/${this.target.id}`;
        },
    },
    mounted() {
        console.log(this.target);
    },
    destroyed() {
        this.setTarget(undefined);
    },
    methods: {
        ...mapMutations({
            updateGenerateLinkAndPasswordPopUpState:
                'popup/updateGenerateLinkAndPasswordPopUpState',
            setTarget: 'sales/setTarget',
        }),
        ...mapActions({
            createCase: 'sales/createCase',
            updateFilter: 'sales/updateFilter',
        }),
        async onCreate() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.onValidate();
                    return true;
                }
                return false;
            });
        },
        async onValidate() {
            const DATA = this.ruleForm.doctorInfo.value;
            const SUBMIT = {
                doctorName: DATA.name,
                doctorUCI: DATA.ucinumber,
                hospital: (DATA.hco.length && DATA.hco[0].hco_id.name) || '',
                accountId: this.user.id,
                CTNumber: this.ruleForm.CTNumber,
            };
            await this.createCase(SUBMIT);
            await this.updateFilter();
        },
        handleClick() {
            const { elSelect } = this.$refs;
            const innerElInput = elSelect.$refs.reference;
            innerElInput.blur();
            innerElInput.focus();
        },
        onCancel() {
            this.updateGenerateLinkAndPasswordPopUpState(false);
        },
        onNextStep() {
            this.updateGenerateLinkAndPasswordPopUpState(false);
            this.$router.push({
                name: 'Sales_Form',
                params: { id: this.target.id },
            });
        },
        onSelect(val) {
            this.$set(this.ruleForm, 'doctorInfo', val);
        },
        async remoteMethod(query) {
            if (query !== '') {
                try {
                    this.loading = true;
                    const RES = await API.CMS.search(query);
                    const DATA = [];
                    RES.data.forEach((item) => {
                        DATA.push({
                            label: item.name,
                            id: item.id,
                            value: item,
                        });
                    });
                    this.hcpList = [...DATA];
                } catch (e) {
                    this.$message({
                        message: e.response.data.msg,
                        type: 'error',
                    });
                } finally {
                    this.loading = false;
                }
            } else this.hcpList = [];
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: #000000;
    font-size: 24px;
    line-height: 35px;
}
.divider {
    border-bottom: 1px solid #333;
    margin-top: 20px;
    // margin-bottom: 20px;
}
.form {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .input-block {
        display: grid;
        grid-template-columns: 120px auto 100px;
        align-items: center;
        margin-top: 20px;
    }
    .input {
        margin-right: 0;
    }
    .el-form-item__label,
    .el-form-item__content {
        font-size: 18px !important;
    }
    .el-form--inline .el-form-item {
        margin-right: 0;
    }
}
.button-group {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    > * {
        margin-right: 10px;
    }
    :last-child {
        margin-right: 0;
    }
}
.button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
</style>
